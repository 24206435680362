// Import react elements
import React from "react";

// Import css
import "./CreateButton.css"; // Import styles



// Create a button based on values passed in
const CreateButton = ({

    // Default size m
    size = "m",

    // Default --primary-colour so send color if you don't want the primary value
    color = "var(--primary-color)",

    // Default use gradient = false
    gradient = false,

    // Defualt is full width = false
    fullWidth = false,

    // Default type is button (could be submit)
    type = "button",

    // Defualt is false
    disabled = false,

    // An icon if it's passed in
    icon,

    // The title if it's passed in
    title,

    // OnClick function passed in
    onClick



}) => {

    return (

        <button

            type={type}

            className={`btn btn-${size} btn-${color} ${fullWidth ? "btn-fullwidth" : ""}`}

            style={{
                background: gradient
                    ? `linear-gradient(45deg, ${color}, var(--primary-color-dark))`
                    : color,
            }}

            onClick={onClick}

            disabled={disabled}
        >

            {icon && <img src={icon} alt="icon" className="btn-icon" />}

            {title && <span className="btn-title">{title}</span>}


        </button>

    );

};

export default CreateButton;
