// Import react elements
import { useContext } from "react";

// Import strich api calls
import { requestEmailChange } from "api/APIStrichCalls";

// Import context function
import { AuthContext } from "global/context/AuthContext";

// Import components
import { GridItem } from "global/components/Grid";
import CreateButton from "global/components/CreateButton";

// Import utility functions
import { DisplayConfirmationDialog } from "global/utilities/DialogBox";


const AccountDetails = (userData) => {



    // Get the authentication token from the AuthContext (determines whether the user is logged in)
    const { token } = useContext(AuthContext);



    const handleUpdateEmail = async () => {

        // Check if there's an email
        if (!userData?.email) {

            alert("No email available");

            // Exit early
            return;

        }    

        // Make the call
        const response = await requestEmailChange(token);

        // Check if the response is successful
        if (response?.success) {

            // It is so display a confrimation dialog
            DisplayConfirmationDialog(

                // Dialog message
                "Check your email to update your email.",

                // Confirm button title
                "OK",

                // Cancel button title - empty because we don't want a cancel button
                ""

            );

        } else {

            alert(response?.error || response?.error_message || "Oops...something went wrong.");

            console.error("API error:", response);

        }

    };



    return (

        <GridItem>

            <h3> Account Details</h3>

            <br />

            <label className="form-label">Email</label>
            <input type="email" name="email" value={userData.email || ""} disabled />

            <br />
            <br />

            <CreateButton title="Update Email" size="l" onClick={handleUpdateEmail} />

        </GridItem>

    );

};

export default AccountDetails;