// Global boolean converters



/**
 * Converts a boolean to "Y" or "N"
 * @param {boolean} value - true or false
 * @returns {"Y" | "N"}
 */

export const B2S = (value) => (value === true ? "Y" : "N");


/**
 * Converts "Y" or "N" to a boolean
 * @param {"Y" | "N" | undefined | null} value
 * @returns {boolean}
 */

export const S2B = (value) => value === "Y";