import "./ToggleSwitch.css";

const ToggleSwitch = ({

    name,

    value = false,

    onChange,

    label = "",

    labelPosition = "left",

    disabled = false,

}) => {

    return (

        <div className={`switch-wrapper ${disabled ? "disabled" : ""}`}>

            <label className="switch-label">

                {label && labelPosition === "left" && <span>{label}</span>}

                <div className="switch-toggle">

                    <input
                        type="checkbox"
                        name={name}
                        checked={!!value}
                        onChange={(e) =>
                            onChange({
                                target: {
                                    name,
                                    value: e.target.checked,
                                },
                            })
                        }
                        disabled={disabled}
                    />

                    <span className="slider" />

                </div>

                {label && labelPosition === "right" && <span>{label}</span>}

            </label>

        </div>

    );

};

export default ToggleSwitch;
