// Import react elements
import { useLocation, useNavigate } from "react-router-dom";

// Import components
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";
import ResetPasswordForm from "./components/ResetPasswordForm";
import UpdatePasswordForm from "./components/UpdatePasswordForm";
import UpdateEmailAddressForm from "./components/UpdateEmailAddressForm";

// Import css style sheet
import styles from "./LoginPage.module.css";



const Login = () => {



    // Set location to useLocation() which gives access to the current URL/location object
    const urlLocation = useLocation();



    // Set navigate to useNavigate() which provides a function to programmatically navigate between routes
    const navigate = useNavigate();



    // Determine which form to show based on current URL path

        // Set the url path name
        const path = urlLocation.pathname;

        // Set the url parameters
        const params = new URLSearchParams(urlLocation.search);

        // Set a value for the login form type
        const loginFormType =

            // It's the register new user screen
            path === "/register"

                // So set it to the register form
                ? "register"

                // It's the reset password screen
                : path === "/reset-password"

                    // So set it to the reset password form
                    ? "reset-password"

                        // It's the update password screen
                        : path === "/update-password" && params.get("token")

                            // So set it to the reset password form
                            ? "update-password"

                                // It's the update email screen
                                : path === "/update_email" && params.get("token")

                                    // So set it to the reset password form
                                    ? "update_email"

                    // Default to login form
                    : "login"; 



    // Handle switching between login, register, reset password and update password forms based on user interaction
    const handleLoginFormSwitch = (formType) => {

        // They want to register so navigate to the register form 
        if (formType === "register") navigate("/register");

        // They want to reset the password so navigate to the reset password form 
        else if (formType === "reset-password") navigate("/reset-password");

        // If it's anything else navigate to the login form 
        else navigate("/login");

    };



    return (

        <div>
                    

            <div className={styles.login_page}>

                <div className={styles.login_page_left}>
                    <p>STRICHSUITE</p>
                </div>

                <div className={styles.login_page_right}>

                    <div className={styles.login_page__login_container}>
                        <div className={styles.login_container__form_container}>
                            <h2>WELCOME</h2>
                            <h3>Unleash the Strich.</h3>
                            <br />
                            <div>
                                {loginFormType === "login" && <LoginForm onSwitch={handleLoginFormSwitch} />}
                                {loginFormType === "register" && <RegisterForm onSwitch={handleLoginFormSwitch} />}
                                {loginFormType === "reset-password" && <ResetPasswordForm onSwitch={handleLoginFormSwitch} />}
                                {loginFormType === "update-password" && <UpdatePasswordForm onSwitch={handleLoginFormSwitch} />}
                                {loginFormType === "update_email" && <UpdateEmailAddressForm onSwitch={handleLoginFormSwitch} />}
                            </div>
                        </div>
                    </div>

                </div>


            </div>

        </div>


    );
};

export default Login;