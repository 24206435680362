// Import react elements
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import core functions
import { updateEmailAddress } from "api/APICoreCalls";

// Import global hooks
import useFormHandler from "global/hooks/useFormHandler";

// Import components
import CreateButton from "global/components/CreateButton";

// Import utility functions
import { DisplayConfirmationDialog } from "global/utilities/DialogBox";
import { SetCookie } from "global/utilities/Cookies";



const UpdateEmailAddressForm = ({ onSwitch }) => {



    // Set navigate to useNavigate() which provides a function to programmatically navigate between routes
    const navigate = useNavigate();



    // Set location to useLocation() which gives access to the current URL/location object
    const location = useLocation();



    // Set params from the url parameters
    const params = new URLSearchParams(location.search);



    // Set urlToken to the token in the url path
    const urlToken = params.get("token");



    // Set urlEmail to the email in the url path
    const urlEmail = params.get("email");



    // Extract form-related state and functions from the `useFormHandler` custom hook
    const {

        formValues,     // Stores the current values of form inputs
        handleChange,   // Function to update form values when inputs change
        handleSubmit,   // Function to handle form submission logic
        fieldErrors,    // Object storing validation errors for individual fields
        formError,      // General error message (e.g. API errors)
        loading,        // Boolean indicating whether the form is submitting
        apiResponse,    // Stores the response from the API after submission

    } = useFormHandler({

        // Default values for the form fields
        initialValues: {

            newEmail: "",
            confirmNewPassword: "",
            oldEmail: urlEmail,
            token: urlToken,

        },

        // API function to be called on form submission
        apiCall: updateEmailAddress,

        // Don't pass the token to the api because we get it from the url
        passTokenToAPI: false,

        // Validation function to check the form fields before submission
        validate: (values) => {

            // Set errors to empty
            const errors = {};

            // Validation rules
            if (!values.newEmail.includes("@")) errors.email = "Invalid email";
            if (values.newEmail !== values.confirmNewEmail) errors.confirmNewEmail = "Email addresses must match";

            // Return validation errors (if any)
            return errors;
        },
    });



    // useEffect runs whenever `apiResponse` changes
    useEffect(() => {

        // Check if there is a response
        if (apiResponse) {

            // There is so check if it's a successful response
            if (apiResponse.success) {

                // It is so display a confrimation dialog
                DisplayConfirmationDialog(

                    // Dialog message
                    "Email address updated, please log in.",

                    // Confirm button title
                    "OK",

                    // Cancel button title - empty because we don't want a cancel button
                    "",

                    // Returned response from the dialog box
                    function (is_confirmed) {

                        // Check if the response is TRUE
                        if (is_confirmed) {

                            // It is

                            // Set the prefill email cookie
                            SetCookie("prefill_email", urlEmail, 1);

                            // Navigate to the login page
                            navigate("/login");

                        }

                    }
                );

            }
        }

    }, [apiResponse, navigate, urlEmail]);



    return (

        <div className="form-container">
            <h2>Update Email Address</h2>
            <br />

            <form onSubmit={handleSubmit}>

                <input
                    name="newEmail"
                    type="email"
                    placeholder="New Email"
                    value={formValues.newEmail || ""}
                    onChange={handleChange}
                />
                {fieldErrors.newEmail && <p style={{ color: "red" }}>{fieldErrors.newEmail}</p>}

                <br />
                <br />

                <input
                    name="confirmNewEmail"
                    type="email"
                    placeholder="Confirm New Email"
                    value={formValues.confirmNewEmail || ""}
                    onChange={handleChange}
                />
                {fieldErrors.confirmNewEmail && <p style={{ color: "red" }}>{fieldErrors.confirmNewEmail}</p>}


                {formError && <p style={{ color: "red" }}>{formError}</p>}
                <br />
                <br />

                <CreateButton title={loading ? "Updating Email..." : "Update Email"} size="l" fullWidth="true" type="submit" disabled={loading} />

            </form>

            <br />
            <br />
            <CreateButton title="Back to Login" size="s" onClick={() => onSwitch("login")} />

        </div>
    );
};

export default UpdateEmailAddressForm;