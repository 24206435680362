// Import react elements
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Import core functions
import { registerNewAccount } from "api/APICoreCalls";

// Import global hooks
import useFormHandler from "global/hooks/useFormHandler";

// Import components
import CreateButton from "global/components/CreateButton";

// Import utility functions
import { DisplayConfirmationDialog } from "global/utilities/DialogBox";
import { SetCookie } from "global/utilities/Cookies";



const RegisterForm = ({ onSwitch }) => {



    // Set navigate to useNavigate() which provides a function to programmatically navigate between routes
    const navigate = useNavigate();



    // Extract form-related state and functions from the `useFormHandler` custom hook
    const {

        formValues,     // Stores the current values of form inputs
        handleChange,   // Function to update form values when inputs change
        handleSubmit,   // Function to handle form submission logic
        fieldErrors,    // Object storing validation errors for individual fields
        formError,      // General error message (e.g. API errors)
        loading,        // Boolean indicating whether the form is submitting
        apiResponse,    // Stores the response from the API after submission

    } = useFormHandler({

        // Default values for the form fields
        initialValues: {

            username: "",
            email: "",
            password: "",
            confirmPassword: "",

        },

        // API function to be called on form submission
        apiCall: registerNewAccount,


        // Validation function to check the form fields before submission
        validate: (values) => {

            // Set errors to empty
            const errors = {};

            // Validation rules
            if (!values.email.includes("@")) errors.email = "Invalid email";
            if (values.password.length < 6) errors.password = "Password must be at least 6 characters";
            if (values.password !== values.confirmPassword) errors.confirmPassword = "Passwords must match";
            if (!values.username.trim()) errors.username = "Username is required";

            // Return validation errors (if any)
            return errors;
        },
    });



    // useEffect runs whenever `apiResponse` changes
    useEffect(() => {

        // Check if there is a response
        if (apiResponse) {

            // Check if it is successful
            if (apiResponse.success) {

                // It is so display a confrimation dialog
                DisplayConfirmationDialog(

                    // Dialog message
                    "Check your email to validate.",

                    // Confirm button title
                    "OK",

                    // Cancel button title - empty because we don't want a cancel button
                    "",

                    // Returned response from the dialog box
                    function (is_confirmed) {

                        // Check if the response is TRUE
                        if (is_confirmed) {

                            // It is

                            // Set the prefill email cookie
                            SetCookie("prefill_email", formValues.email, 1);

                            // Navigate to the login page
                            navigate("/login");

                        }
                    }
                );

            }
        }

    }, [apiResponse, formValues.email, navigate]);



    return (

        <div>

            <form onSubmit={handleSubmit}>
                <input
                    name="username"
                    type="text"
                    placeholder="Username"
                    value={formValues.username}
                    onChange={handleChange}
                />
                {fieldErrors.username && <p style={{ color: "red" }}>{fieldErrors.username}</p>}

                <br />
                <br />

                <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={formValues.email}
                    onChange={handleChange}
                />
                {fieldErrors.email && <p style={{ color: "red" }}>{fieldErrors.email}</p>}

                <br />
                <br />

                <input
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={formValues.password}
                    onChange={handleChange}
                />
                {fieldErrors.password && <p style={{ color: "red" }}>{fieldErrors.password}</p>}

                <br />
                <br />

                <input
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                    value={formValues.confirmPassword}
                    onChange={handleChange}
                />
                {fieldErrors.confirmPassword && <p style={{ color: "red" }}>{fieldErrors.confirmPassword}</p>}

                <br />
                <br />

                {formError && <p style={{ color: "red" }}>{formError}</p>}

                <CreateButton title={loading ? "Registering..." : "Register"} size="l" fullWidth="true" type="submit" disabled={loading} />

            </form>

            <br />

            <p>
                Already have an account? <span onClick={() => onSwitch("login")} style={{ cursor: "pointer" }}><b>Log In</b></span>
            </p>

        </div>

    );
};

export default RegisterForm;









