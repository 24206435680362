// Import react elements
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Import context function
import { AuthContext } from "global/context/AuthContext";

// Import components
import CreateButton from "global/components/CreateButton";

// Import utility functions
import { GetCookie, SetCookie } from "global/utilities/Cookies"



const LoginForm = ({ onSwitch }) => {



    // Set the prefill_email with the prefill_email_cookie
    const prefill_email = GetCookie("prefill_email");



    // Extract the `login` function from the authentication context - allows us to call `login()` anywhere inside this component without needing to manually pass it down as a prop
    const { login } = useContext(AuthContext);



    // Set navigate to useNavigate() which provides a function to programmatically navigate between routes
    const navigate = useNavigate();



    // Set the defualt form values
    const [email, setEmail] = useState(prefill_email || "");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");



    // Handle the submit button on login form
    const handleSubmitLogin = async (event) => {

        // Prevent page reload
        event.preventDefault();

        // Run the api call
        const response = await login(email, password);

        // Check if the response is successful
        if (response.success) {

            // It is

            // Set prefill_email cookie to the user's email
            SetCookie("prefill_email", email, 1);

            // Navigate to the dashboard
            navigate("/dashboard"); // Redirect after login

        } else {

            // It isn't

            // Set the 'error' value in the form so it can be displayed to the user
            setError(response.error_message);

        }

    };

    

    return (

        <div>

            <form>

                <input type="email" name="email" placeholder="Email" onChange={(event) => setEmail(event.target.value)} required value={email || ""} />
                <br />
                <br />
                <input type="password" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} required />
                <br />
                <br />
                <p>
                    <span onClick={() => onSwitch("reset-password")} style={{ cursor: "pointer" }}><b>Forgotten Password?</b></span>
                </p>
                <br />
                {error && <p style={{ color: "red" }}>{error}</p>}
                <br />

                <CreateButton title="Login" size="l" fullWidth="true" onClick={handleSubmitLogin} />

            </form>

            <br />
            <br />

            <p>
                Don't have an account? <span onClick={() => onSwitch("register")} style={{ cursor: "pointer" }}><b>Sign Up</b></span>
            </p>

        </div>
    );

};

export default LoginForm;