
// Import components
import { GridItem } from "global/components/Grid";
import CreateButton from "global/components/CreateButton";
import ConnectionsTable from "./ConnectionsTable";



const Connections = (connectionsData, selectedConnection, setSelectedConnection, addingNewConnection, setAddingNewConnection, handleSelectConnection ) => {

    //console.log(addingNewConnection);

    return (

        <GridItem>

            <h3>Connections</h3>


            { connectionsData ?

                <ConnectionsTable connectionsData={connectionsData} selectedConnection={selectedConnection} setSelectedConnection={setSelectedConnection} addingNewConnection={addingNewConnection} handleSelectConnection={handleSelectConnection}  />

                :

                <p>Loading...</p>

            }

            <br/>


            {/* Greyed out when adding new connection with option to save or cancel */}
            <CreateButton
                title="+ Add New Connection"
                size="m"
                disabled={addingNewConnection}
                onClick={() => {
                    setAddingNewConnection(true)
                    setSelectedConnection(null)
                }}
            />


        </GridItem>

    );

};

export default Connections;