// Import react elements
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Import context function
import { AuthContext } from "global/context/AuthContext";

// Import utility functions
import { B2S } from "global/utilities/BooleanConverters";



/**
 * Handles form state and submission logic
 * 
 * `config` is an object
 * @param {Object} config 
 * Object with default field values
 * @param {Object} config.initialValues
 * Async function to call on submit (api call)
 * @param {Function} config.apiCall
 * Optional validator returning field-level errors
 * @param {Function} [config.validate]
 * Optional redirect path
 * @param {string} [config.redirectOnSuccess]
 */




// To Add: 
// Need to put something in to only send 'updated' data to api call so we don't send everything if only 1 or 2 things have been edited
// EP 08/04/2025



const useFormHandler = ({ initialValues, apiCall, validate, redirectOnSuccess, syncInitialValues = false, convertBooleans = false, passTokenToAPI = true }) => {


    //console.log("Initial Values: ", initialValues);


    // Get the token from AuthContext
    const { token } = useContext(AuthContext);



    // Set navigate to useNavigate() which provides a function to programmatically navigate between routes
    const navigate = useNavigate();



    // Set initial states
    // This ensures React only runs the initializer once, and won�t get 'undefined' during rerenders
    const [formValues, setFormValues] = useState(() => ({ ...initialValues }));
    const [fieldErrors, setFieldErrors] = useState({});
    const [formError, setFormError] = useState("");
    const [loading, setLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);



    // Track initialValues for one-time sync when form values change (based on user clicking another list item)
    useEffect(() => {

        // Check if we need to sync initial values (we don't need to on log in pages)
        if (syncInitialValues) {

            // We do

            // Set the formValues to the inital values
            setFormValues(initialValues);
        }

        // Only runs when initialValues changes (due to useMemo)
    }, [syncInitialValues, initialValues]);



    
    // Automatically sync once to updated initialValues into formValues - This will re-initialize the form values like when switching or clearing a selected item
    useEffect(() => {

        setFormValues(initialValues);


    }, []);

    



    // Handles changes in form input fields and updates state accordingly when user types in them
    const handleChange = (event) => {

        // Destructure the `name` and `value` from the event's target (the input field)
        const { name, value } = event.target;

        // Update the form values state - Preserve previous values (`...prev`) - Update only the field that changed (`[name]: value`)
        setFormValues((prev) => ({ ...prev, [name]: value }));      

        // Clear any existing validation error as the user types - Preserve previous field errors (`...prev`) - Set the current field�s error to an empty string
        setFieldErrors((prev) => ({ ...prev, [name]: "" }));

    };



    // Handle form submission
    const handleSubmit = async (event) => {

        // Prevents page reload
        event.preventDefault();

        // Clear previous form errors
        setFormError("");

        // Reset all field-specific errors
        setFieldErrors({});

        // Set 'Loading' state to true while we validate the form and run the api call
        setLoading(true);

        // Clear previous API response
        setApiResponse(null);



        // Check if a validation function is provided, use it to check for errors
        if (validate) {

            // It is

            // Set errors based on validating the form input values
            const errors = validate(formValues);

            // Check if there are validation errors
            if (Object.keys(errors).length > 0) {

                // There are

                // Update error state
                setFieldErrors(errors);

                // We have errors so stop loading
                setLoading(false);

                // Stop submission because we have an error
                return;

            }

        }







        // Prepare the data for the API call to convert any boolean values to a string 'Y' or 'N' if convertBoolean is true
        const preparedData = convertBooleans

            ? Object.fromEntries(

                // Map the formValues
                Object.entries(formValues).map(([key, value]) => [
                    key,

                    // Check if the value is a boolean - if so run the B2S function
                    typeof value === "boolean" ? B2S(value) : value,
                ])
            )

            // Else set it to formValues
            : formValues;



        //console.log("prepared data: ", preparedData);

        // Set response for api, check if token is available and we want to include it in the call, (if so include it in the API call) 
        const response = token && passTokenToAPI

            // Run the api call with the token and form values
            ? await apiCall(token, preparedData, setApiResponse)

            // Run the api call with the form values
            : await apiCall(preparedData, setApiResponse);



        // Store the api response in a state so we can return it
        setApiResponse(response);

        //console.log(response);
               
        // Set 'Loading' state to false now api call has finished
        setLoading(false);



        // Check if the api call was unsuccessful
        if (!response?.success) {

            // It was unsuccessful

            // Set the formError state with the api response error
            setFormError(response?.error || response.error_message || "Something went wrong");

        }



        // Optional redirect
        // Check if the api response is successful and redirectOnSuccess is passed in
        if (response?.success && redirectOnSuccess) {

            // They both are

            // Navigate to the redirect location
            navigate(redirectOnSuccess);

        }

    };



    // Function to reset the form with original values
    const resetForm = (newValues = initialValues) => {

        // Set the form values to the initial form values before editing
        setFormValues(newValues);

        // Clear the field errors
        setFieldErrors({});

        // Clear the form errors
        setFormError("");

        // Clear the api response
        setApiResponse(null);

    };



    // Function to check if the form has been edited
    const isFormPartlyEdited = () => {

        // Return true/false based on whether the current form values are the same at the initial values
        return JSON.stringify(formValues) !== JSON.stringify(initialValues);

    };



    return {
        formValues,
        setFormValues,
        handleChange,
        handleSubmit,
        resetForm,
        fieldErrors,
        formError,
        loading,
        apiResponse,
        isFormPartlyEdited,
    };
};

export default useFormHandler;