// Import API call
import { strichCall } from './APICoreCalls';



export function getUserAccountData(token, set_account_details) {

    strichCall("GET", "accounts/account", token)
        .then((response) => {
            //console.log(response)
            set_account_details(response["results"]);
        })

    return;
}

export function getConnections(token, set_connections_details) {

    strichCall("GET", "connections/connections", token)
        .then((response) => {
            //console.log(response)
            set_connections_details(response["results"]);
        })

    return;
}




// Request Change Account Email Address
export async function requestEmailChange(token) {

    const response = await strichCall("GET", "accounts/request_email_change", token);

    //console.log(" Response:", response);

    return response;

}









// Request Change Account Email Address
export async function getOrders(token) {

    const response = await strichCall("GET", "orders/orders", token);

    console.log(" Response:", response);

    return response;

}


// Needs order id and connection id for the call
export function _getOrders(token, setOrdersData) {

    strichCall("GET", "orders/orders", token)
        .then((response) => {
            console.log(response)
            setOrdersData(response["results"]);
        })

    return;
}















// Update Connection
export async function updateConnection(token, updated_connection_details) {

    // Convert the data to correct format for the call
    const updatedConnectionDetails = JSON.stringify(Object.assign({}, updated_connection_details))

    //console.log(updatedConnectionDetails);

    try {

        const response = await strichCall("PUT", "connections/connections", token, updatedConnectionDetails);

        //console.log(" Response:", response);
        // If response?.results is null or undefined, fall back to response - depending on what the response is 
        return response?.results ?? response;

    } catch (error) {

        //console.error(" API Error:", error);
        return { success: false, error: "Failed to update connection." };

    }
}
// Add New Connection
export async function addNewConnection(token, new_connection_details) {

    // Convert the data to correct format for the call
    const newConnectionDetails = JSON.stringify(Object.assign({}, new_connection_details))

    //console.log(newConnectionDetails);

    try {

        const response = await strichCall("POST", "connections/connections", token, newConnectionDetails);

        return response;

    } catch (error) {

        //console.error(" API Error:", error);
        return { success: false, error: "Failed to add connection." };

    }
}

