// Import react elements
import { useContext, useEffect, useState } from "react";

// Import context function
import { UserContext } from "global/context/UserContext";

// Import hooks
import usePageTitle from "global/hooks/usePageTitle";

// Import components
import { Grid } from "global/components/Grid";
import Connections from "./components/Connections";
import ConnectionDetails from "./components/ConnectionDetails";

// Import utility functions
import { DisplayConfirmationDialog } from "global/utilities/DialogBox";



// AccountPage.js
const Account = () => {



    // Set title inside the page
    usePageTitle("Account - Connections");



    // Extract the connections data from the user context - allows us to use their data anywhere inside this component without needing to manually pass it down as a prop
    const { connectionsData } = useContext(UserContext);



    // Set null state for selected connection
    const [selectedConnection, setSelectedConnection] = useState([]);



    // Function to set the default selected connection - so we can display info in the form
    useEffect(() => {

        // Set the default connection - check it's an array
        const defaultConnection = Array.isArray(connectionsData)

            // It's an array so find the first connection (based on user order)
            ? connectionsData.find(conn => conn.userorder === 1)

            // It's not an array so set it to null
            : null;

        // Set the selected connection to the default connection 
        setSelectedConnection(defaultConnection || null);

        // Run when connections data changes / updates
    }, [connectionsData]);


    // Set a state for adding a new connection
    const [addingNewConnection, setAddingNewConnection] = useState(false);



    // Set state to check if form is partly edited so we don't let them click to another connection
    const [formIsPartlyEdited, setFormIsPartlyEdited] = useState(false);

    // Function to handle the select connection click (in connections table)
    const handleSelectConnection = (newConn) => {

        // Check if the selected connection id is the same as the new one that we've clicked - if it is return early
        if (selectedConnection?.id === newConn.id) return;

        // Check if the form has been partly edited
        if (formIsPartlyEdited) {

            // It has

            // Display a confrimation dialog
            DisplayConfirmationDialog(

                // Dialog message
                "You have unsaved changes. Are you sure you want to change the connection?",

                // Confirm button title
                "Yes, discard changes",

                // Cancel button title
                "No, keep editing",

                // Returned response from the dialog box
                function (is_confirmed) {

                    // Check if the response is TRUE
                    if (is_confirmed) {

                        // It is

                        // Update the selected connection
                        setSelectedConnection(newConn);

                    }

                }

            );

        } else {

            // It hasn't so update the selected connection with the new connection
            setSelectedConnection(newConn);

        }
        
    };



    return (

        <>

            <Grid>

                {Connections(connectionsData, selectedConnection, setSelectedConnection, addingNewConnection, setAddingNewConnection, handleSelectConnection)}

            </Grid>

            <Grid>

                {ConnectionDetails(connectionsData, selectedConnection, setSelectedConnection, addingNewConnection, setAddingNewConnection, setFormIsPartlyEdited)}

            </Grid>

        </>

    );

};

export default Account;