// Import react elements
import { useContext, useEffect, useMemo, useState } from "react";

// Import core functions
import { updateConnection } from "api/APIStrichCalls";
import { addNewConnection } from "api/APIStrichCalls";

// Import context function
import { UserContext } from "global/context/UserContext";

// Import components
import { GridItem } from "global/components/Grid";
import CreateButton from "global/components/CreateButton";
import ToggleSwitch from "global/components/ToggleSwitch"

// Import global hooks
import useFormHandler from "global/hooks/useFormHandler";

// Import utility functions
import { DisplayConfirmationDialog } from "global/utilities/DialogBox";
import { S2B } from "global/utilities/BooleanConverters";



const ConnectionDetails = (connectionsData, selectedConnection, setSelectedConnection, addingNewConnection, setAddingNewConnection, setFormIsPartlyEdited ) => {

    //console.log("Connections Details data:", selectedConnection)


    const { refreshUserData } = useContext(UserContext);


    const [reselectConnectionId, setReselectConnectionId] = useState(null);


    const [isEdittingForm, setIsEdittingForm] = useState(false);


    // Set the initial form values
    const initialFormValues = useMemo(() => {

        //console.log("Set initail form values:", selectedConnection);

        return selectedConnection

            // There's a selected connection so set that data
            
            ? {
                id: selectedConnection.id || "",
                connectionname: selectedConnection.connectionname || "",
                conntype: selectedConnection.conntype || "",
                active: S2B(selectedConnection.active),

            }
            :

            // There's no selected connection to set form's default values

            {

                connectionname: "",
                id: "",
                conntype: "",
                active: false,

            };

    }, [selectedConnection]);



    // Extract form-related state and functions from the `useFormHandler` custom hook
    const {

        formValues,     // Stores the current values of form inputs
        handleChange,   // Function to update form values when inputs change
        handleSubmit,   // Function to handle form submission logic
        resetForm,
        isFormPartlyEdited,
        fieldErrors,    // Object storing validation errors for individual fields
        formError,      // General error message (e.g. API errors)
        loading,        // Boolean indicating whether the form is submitting
        apiResponse,    // Stores the response from the API after submission



    } = useFormHandler({

        // Default values for the form fields
        initialValues: initialFormValues,

        // Enable syncing - because we're using memo to remeber the initial form values before the user edits them
        syncInitialValues: true, 

        // API function to be called on form submission
        apiCall: addingNewConnection ? addNewConnection : updateConnection,

        // We want to convert boolean to string
        convertBooleans: true,

        // Validation function to check the form fields before submission
        validate: (values) => {

            // Set errors to empty
            const errors = {};

            // Validation rules
            

            // Return validation errors (if any)
            return errors;
        },
    });



    // Check if the form is partly edited before updating the values
    useEffect(() => {

        if (setFormIsPartlyEdited) {

            setFormIsPartlyEdited(isFormPartlyEdited());
        }

        // Re-evaluate when form values change
    }, [formValues]); 



    // useEffect runs whenever `apiResponse` changes
    useEffect(() => {

        // Check if there is a response
        if (apiResponse) {

            // Check if it is successful
            if (apiResponse.success) {

                // Check if we're adding a new connection
                if (addingNewConnection) {

                    // It's a new connection

                    // Display a confrimation dialog
                    DisplayConfirmationDialog(

                        // Dialog message
                        "A new connection has been added.",

                        // Confirm button title
                        "OK",

                        // Cancel button title - empty because we don't want a cancel button
                        "",

                        // Returned response from the dialog box
                        function (is_confirmed) {

                            // Check if the response is TRUE
                            if (is_confirmed) {

                                // It is

                                // Set to false beacuse we're not adding a new connection anymore
                                setAddingNewConnection(false);

                                // Set setIsEdittingForm state to false beacuse we're not editing the form anymore
                                setIsEdittingForm(false);

                            }
                        }

                    );

                    // Wrap async logic in a self-invoking async function
                    (async () => {

                        // Set the selected connection to the recently added one
                        setReselectConnectionId(apiResponse.results.id);

                        // Await refresh userData from UserContext
                        await refreshUserData();

                    })();

                } else {

                    // We're updating an existing connection

                    // Display a confrimation dialog
                    DisplayConfirmationDialog(

                        // Dialog message
                        "The connection has been updated.",

                        // Confirm button title
                        "OK",

                        // Cancel button title - empty because we don't want a cancel button
                        "",

                        // Returned response from the dialog box
                        function (is_confirmed) {

                            // Check if the response is TRUE
                            if (is_confirmed) {

                                // It is

                                // Set to false beacuse we're not adding/editing a connection anymore
                                setAddingNewConnection(false);

                                // Set setIsEdittingForm state to false beacuse we're not editing the form anymore
                                setIsEdittingForm(false);

                            }

                        }

                    );

                    // Wrap async logic in a self-invoking async function
                    (async () => {

                        // Set the ID we want to restore after the refresh
                        setReselectConnectionId(selectedConnection?.id);

                        // Await refresh userData from UserContext
                        await refreshUserData();

                    })();

                }

            }

        }

    }, [apiResponse]);



    // Reselect connection after refresh
    useEffect(() => {

        //console.log(reselectConnectionId);

        // Check if there's an ID to reselect AND the connectionsData has been populated
        if (reselectConnectionId && connectionsData?.length > 0) {

            // Find the connection with the matching ID
            const updatedConnection = connectionsData.find(conn => conn.id === reselectConnectionId);

            // Check for an updated connection
            if (updatedConnection) {

                // There is one so re-select that connection
                setSelectedConnection(updatedConnection);
                //setReselectConnectionId(null);

            }
        }

        // Watches for changes in:
        // - `connectionsData` (updated connection list from the server)
        // - `reselectConnectionId` (ID of the connection we want to restore selection to)
        // - `setSelectedConnection` (included for consistency/safety in React dependency array)
    }, [connectionsData, reselectConnectionId, setSelectedConnection]);




    /*
    // Console log selected connection / initial values when selectedConnection changes - DEBUGGING
    useEffect(() => {

        console.log("Selected connection has changed: ", selectedConnection);
        console.log("Initial 'active' value: ", initialFormValues.active);

        //setSelectedConnection(updatedConnection);

    }, [selectedConnection]);
    */


    // Function to cancel form edits (for Cancel button)
    const cancelFormEdit = () => {

        // Check if the form has been edited
        if (isFormPartlyEdited()) {

            // It has

            // Display a confrimation dialog
            DisplayConfirmationDialog(

                // Dialog message
                "You have unsaved changes. Are you sure you want to cancel?",

                // Confirm button title
                "Yes, discard changes",

                // Cancel button title
                "No, keep editing",

                // Returned response from the dialog box
                function (is_confirmed) {

                    // Check if the response is TRUE
                    if (is_confirmed) {

                        // It is

                        // Set resetForm to inital values because we want to cancel any edits
                        resetForm(initialFormValues);

                        // Set AddingNewConnection state to false beacuse we're not adding a new connection anymore
                        setAddingNewConnection(false);

                        // Set setIsEdittingForm state to false beacuse we're not editing the form anymore
                        setIsEdittingForm(false);

                    }

                }

            );

        } else {

            // Set resetForm to inital values because we want to cancel any edits
            resetForm(initialFormValues);

            // Set AddingNewConnection state to false beacuse we're not adding a new connection anymore
            setAddingNewConnection(false);

            // Set setIsEdittingForm state to false beacuse we're not editing the form anymore
            setIsEdittingForm(false);

        }
    };



    // DEBUGGING
    //console.log("is form edited: ", isFormPartlyEdited());
    //console.log("adding new: ", addingNewConnection);
    //console.log(connectionsData);



    if (!selectedConnection && !addingNewConnection && (!formValues || formValues.active === undefined)) return null;

    return (

        <GridItem>

            <h3>Connection Details</h3>

            <br/>

            
                <form onSubmit={handleSubmit}>

                <label>Connection Name</label>
                <input
                    name="connectionname"
                    type="text"
                    value={formValues.connectionname}
                    onChange={handleChange}
                    disabled={(!addingNewConnection && selectedConnection === null) || (!isEdittingForm && selectedConnection)}
                />
                <br />
                {fieldErrors.connectionname && <p style={{ color: "red" }}>{fieldErrors.email}</p>}
                <br />
                <label>Connection Type</label>
                <input
                    name="conntype"
                    type="text"
                    value={formValues.conntype}
                    onChange={handleChange}
                    disabled={(!addingNewConnection && selectedConnection === null) || (!isEdittingForm && selectedConnection)}
                />
                <br />
                {fieldErrors.conntype && <p style={{ color: "red" }}>{fieldErrors.email}</p>}
                <br />
                <ToggleSwitch
                    name="active"
                    label="Active"
                    value={formValues.active ?? false}
                    onChange={(event) => {
                        handleChange(event);
                    }}
                    disabled={(!addingNewConnection && selectedConnection === null) || (!isEdittingForm && selectedConnection)}
                />
                <br />
                <br />


                {formError && <p style={{ color: "red" }}>{formError}</p>}

                <br />


                {addingNewConnection || isEdittingForm ?

                    <>

                        <CreateButton title={loading ? "Saving..." : "Save"} size="m" type="submit" disabled={loading} />

                        <CreateButton title="Cancel" size="m" type="button" onClick={() => cancelFormEdit()} disabled={loading} />

                    </>

                    : !addingNewConnection && selectedConnection === null ?

                        ""
                        :

                        <CreateButton title="Edit Connection" size="m" type="button" onClick={() => setIsEdittingForm(prev => !prev)} disabled={loading} />

                }




                </form>

            


        </GridItem>

    );

};

export default ConnectionDetails;


// value={selectedConnection ? selectedConnection.connectionname : "" }