
const ConnectionsTable = ({ connectionsData, selectedConnection, setSelectedConnection, addingNewConnection, handleSelectConnection }) => {


   //console.log("Connections Table data:", connectionsData)

    // Check if there is any connections data or that the array of connections is more than 0
    if (!connectionsData || connectionsData.length === 0) {

        // Display to user
        return <p>No connections found.</p>;

    }


    return (


        <table className="connections-table">

            <thead>

                <tr>
                    <th>User Order</th>
                    <th>ID</th>
                    <th>Connection Name</th>
                    <th>Connection Type</th>
                    <th>Active</th>
                    <th>Last Synched</th>
                </tr>

            </thead>

            <tbody>

                {connectionsData.map((conn) => (
                    <tr key={conn.userorder}
                        className={selectedConnection?.id === conn.id ? "selected" : ""}

                        onClick={() => {
                            if (!addingNewConnection) {
                                handleSelectConnection(conn);
                            }
                        }}
                    >
                        <td>{conn.userorder}</td>
                        <td>{conn.id}</td>
                        <td>{conn.connectionname}</td>
                        <td>{conn.conntype}</td>
                        <td>{conn.active}</td>
                        <td>{conn.lastsynched}</td>
                        
                    </tr>
                ))}

            </tbody>

        </table>


    );

};

export default ConnectionsTable;