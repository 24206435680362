// Import react elements
import { createContext, useContext, useState, useEffect } from "react";

// Import context
import { AuthContext } from "./AuthContext";



// Create theme context
export const ThemeContext = createContext();



// ThemeProvider is a Context Provider component that wraps the entire app
// It gives all components inside access to theme-related settings (e.g. light, dark, primary colour).
export const ThemeProvider = ({ children }) => {



    // Get the authentication token from the AuthContext (determines whether the user is logged in)
    const { token } = useContext(AuthContext);

    // Set stored theme from localStorage (or use default)
    const storedTheme = localStorage.getItem("theme") || "light";

    // Set stored primary colour from localStorage (or use default)
    const storedPrimary = localStorage.getItem("primary") || "blue";

    // Set state for theme as stored theme
    const [theme, setTheme] = useState(storedTheme);

    // Set state for primary colour as stored primary colour
    const [primary, setPrimary] = useState(storedPrimary);



    // Run on theme / primary colour state change to apply new theme & primary color
    useEffect(() => {

        // Check if user is authenticated
        if (token) {

            // They are logged in 

            // Set the attribute to the new theme
            document.documentElement.setAttribute("data-theme", theme);

            // Set the attribute to the new primary colour
            document.documentElement.setAttribute("data-primary", primary);

            // Save selections in localStorage
            localStorage.setItem("theme", theme);
            localStorage.setItem("primary", primary);

        } else {

            // They are not logged in

            // Remove attributes
            document.body.removeAttribute("data-theme");
            document.body.removeAttribute("data-primary");

            // Clear localStorage
            localStorage.removeItem("theme", theme);
            localStorage.removeItem("primary", primary);

        }

    }, [theme, primary, token]);



    // If unauthenticated, just pass children without theme logic (there's no token so they're not logged in)
    if (!token) return children;



    return (

        // The ThemeContext.Provider makes theme data available to all child components that use the ThemeContext via useContext().
        <ThemeContext.Provider value={{ theme, setTheme, primary, setPrimary }}>

            {/* This renders the entire app inside the provider, giving everything access to theme */}
            {children}

        </ThemeContext.Provider>

    );
};