// Import react elements
import { createContext, useContext, useState, useEffect } from "react";

// Import strich api calls
import { getUserAccountData, getConnections } from "api/APIStrichCalls";

// Import context function
import { AuthContext } from "global/context/AuthContext";



// Create user context
export const UserContext = createContext();



// UserProvider is a Context Provider component that wraps the entire app
// It gives all components inside access to user-related data and functions (e.g. email, username, password).
export const UserProvider = ({ children }) => {



    // Get the token from AuthContext
    const { token, authReady } = useContext(AuthContext);



    // Set a state for the user data retrieved from the API
    const [userData, setUserData] = useState("");

    // Set a state for the connections data retrieved from the API
    const [connectionsData, setConnectionsData] = useState([]);




    // Reusable fetch function
    const refreshUserData = async () => {

        // Check if there's no token or if the authReady flag is false
        if (!authReady || !token)

            // Return early
            return;

        // We have a token and the AuthReady flag is true
        // Make the API call to log in and get the response
        getUserAccountData(token, setUserData);
        getConnections(token, setConnectionsData);

    };


    // Call it once on load
    useEffect(() => {

        refreshUserData();

    }, [token, authReady]);



    /*
    // Run once - get user data from the API when the token is available or changes
    useEffect(() => {

        // Check if there's no token or if the authReady flag is false
        if (!authReady || !token) 

            // Exit early
            return;

        // We have a token and the AuthReady flag is true
        // Make the API call to log in and get the response
        getUserAccountData(token, setUserData);
        getConnections(token, setConnectionsData);


        // Re-run if the token or flag changes
    }, [authReady, token]); 
    */









    return (

        // The AuthContext.Provider makes user's account data (like their eamil, username and password) 
        // available to all child components that use the UserContext via useContext().
        <UserContext.Provider value={{ userData, setUserData, connectionsData, setConnectionsData, refreshUserData }}>

            {/* This renders the entire app inside the provider, giving everything access to user */}
            {children}

        </UserContext.Provider>

    );



};