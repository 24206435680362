// Import react elements
import { useState } from "react";

// Import core functions
import { resetPassword } from "api/APICoreCalls";

import useSubmitHandler from "global/hooks/useSubmitHandler";

// Import components
import { GridItem } from "global/components/Grid";
import CreateButton from "global/components/CreateButton";

// Import utility functions
import { DisplayConfirmationDialog } from "global/utilities/DialogBox";



const UpdatePassword = (userData) => {



    const handleUpdatePassword = async () => {

        // Check if there's an email
        if (!userData?.email) {

            alert("No email available");

            // Exit early
            return;

            }

        // Format email into object as expected by the API
        const formValues = [userData.email];

        // Make the call
        const response = await resetPassword(formValues);

        // Check if the response is successful
        if (response?.success) {

            // It is so display a confrimation dialog
            DisplayConfirmationDialog(

                // Dialog message
                "Check your email to reset your password.",

                // Confirm button title
                "OK",

                // Cancel button title - empty because we don't want a cancel button
                ""

            );

        } else {

            alert(response?.error || response?.error_message || "Oops...something went wrong.");

            console.error("API error:", response);

        }

    };



    return (

        <GridItem>

            <h3>Update Password</h3>

            <br />

            <p>To update your passord, simply click the button below and follow the instructions.</p>

            <br />

            <CreateButton title="Update Password" size="l" onClick={handleUpdatePassword} />

        </GridItem>

    );


};

export default UpdatePassword;